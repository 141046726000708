<template>
    <div class="list-view">
        <page-title>
            <el-button
                @click="addRow"
                type="primary"
                icon="el-icon-plus"
                :disabled="fetchingData || downloading"
                class="filter-item"
            >
                新增
            </el-button>
            <!-- <el-button
                @click="download"
                icon="el-icon-upload2"
                :loading="downloading"
                :disabled="fetchingData"
                class="filter-item"
            >
                导出
            </el-button> -->
        </page-title>
        <div class="filters-container">
            <minter-filter v-model="minterId" @input="initData" :projectId="$store.state.projectId"></minter-filter>
            <el-input
                placeholder="搜索..."
                v-model="search"
                clearable
                class="filter-item search"
                @keyup.enter.native="initData"
            >
                <el-button @click="initData" slot="append" icon="el-icon-search"> </el-button>
            </el-input>
        </div>
        <el-table
            :data="tableData"
            row-key="id"
            ref="table"
            header-row-class-name="table-header-row"
            header-cell-class-name="table-header-cell"
            row-class-name="table-row"
            cell-class-name="table-cell"
            :height="tableHeight"
            v-loading="fetchingData"
        >
            <el-table-column v-if="multipleMode" align="center" type="selection" width="50"> </el-table-column>
            <el-table-column prop="id" label="ID" width="100"> </el-table-column>
            <el-table-column prop="name" label="名称" show-overflow-tooltip> </el-table-column>
            <el-table-column prop="createdAt" label="创建时间" width="150">
                <template slot="header" slot-scope="{ column }">
                    <sortable-header :column="column" :current-sort="sort" @changeSort="changeSort"> </sortable-header>
                </template>
            </el-table-column>
            <el-table-column prop="pic" label="作品内容">
                <template slot-scope="{ row }">
                    <el-image
                        style="width: 30px; height: 30px"
                        :src="row.pic[0].thumb || row.pic[0].url"
                        fit="cover"
                        :preview-src-list="row.pic.map(i => i.thumb || i.url)"
                    ></el-image>
                </template>
            </el-table-column>
            <el-table-column prop="minter" label="铸造者"> </el-table-column>
            <el-table-column prop="minterId" label="铸造者ID"> </el-table-column>
            <el-table-column prop="type" label="类型" :formatter="typeFormatter"> </el-table-column>
            <el-table-column prop="source" label="来源" :formatter="sourceFormatter"> </el-table-column>
            <el-table-column prop="total" label="发行数量"> </el-table-column>
            <el-table-column prop="stock" label="剩余库存"> </el-table-column>
            <el-table-column prop="onShelf" label="上架">
                <template v-slot="{ row }">
                    <el-tag type="success" v-if="row.onShelf">是</el-tag>
                    <el-tag type="info" v-else>否</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="salable" label="仅展示" width="90" align="center">
                <template v-slot="{ row }">
                    <el-tag type="success" v-if="!row.salable">是</el-tag>
                    <el-tag type="info" v-else>否</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="price" label="价格"> </el-table-column>
            <el-table-column prop="serviceCharge" label="手续费(%)" width="90"> </el-table-column>
            <el-table-column prop="sort" label="排序" width="90" align="center">
                <template slot="header" slot-scope="{ column }">
                    <sortable-header :column="column" :current-sort="sort" @changeSort="changeSort"> </sortable-header>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center" fixed="right" width="150">
                <template slot-scope="{ row }">
                    <el-button @click="editRow(row)" type="primary" size="mini" plain>查看</el-button>
                    <el-button @click="deleteRow(row)" type="danger" size="mini" plain>删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="pagination-wrapper">
            <!-- <div class="multiple-mode-wrapper">
                <el-button v-if="!multipleMode" @click="toggleMultipleMode(true)">批量编辑</el-button>
                <el-button-group v-else>
                    <el-button @click="operation1">批量操作1</el-button>
                    <el-button @click="operation2">批量操作2</el-button>
                    <el-button @click="toggleMultipleMode(false)">取消</el-button>
                </el-button-group>
            </div> -->
            <el-pagination
                background
                @size-change="onSizeChange"
                @current-change="onCurrentChange"
                :current-page="page"
                :page-sizes="[10, 20, 30, 40, 50]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalElements"
            >
            </el-pagination>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import pageableTable from '@/mixins/pageableTable';

export default {
    name: 'CollectionList',
    mixins: [pageableTable],
    data() {
        return {
            multipleMode: false,
            search: '',
            url: '/collection/all',
            downloading: false,
            typeOptions: [
                { label: '默认', value: 'DEFAULT' },
                { label: '盲盒', value: 'BLIND_BOX' },
                { label: '拍卖', value: 'AUCTION' }
            ],
            sourceOptions: [
                { label: '官方', value: 'OFFICIAL' },
                { label: '用户铸造', value: 'USER' },
                { label: '转让', value: 'TRANSFER' }
            ],
            minterId: ''
        };
    },
    computed: {
        selection() {
            return this.$refs.table.selection.map(i => i.id);
        }
    },
    methods: {
        typeFormatter(row, column, cellValue, index) {
            let selectedOption = this.typeOptions.find(i => i.value === cellValue);
            if (selectedOption) {
                return selectedOption.label;
            }
            return '';
        },
        sourceFormatter(row, column, cellValue, index) {
            let selectedOption = this.sourceOptions.find(i => i.value === cellValue);
            if (selectedOption) {
                return selectedOption.label;
            }
            return '';
        },
        beforeGetData() {
            return { search: this.search, query: { del: false, type: 'BLIND_BOX', minterId: this.minterId, source: "OFFICIAL"} };
        },
        toggleMultipleMode(multipleMode) {
            this.multipleMode = multipleMode;
            if (!multipleMode) {
                this.$refs.table.clearSelection();
            }
        },
        addRow() {
            this.$router.push({
                path: '/blindBoxEdit',
                query: {
                    ...this.$route.query
                }
            });
        },
        editRow(row) {
            this.$router.push({
                path: '/blindBoxEdit',
                query: {
                    id: row.id
                }
            });
        },
        download() {
            this.downloading = true;
            this.$axios
                .get('/collection/excel', {
                    responseType: 'blob',
                    params: { size: 10000 }
                })
                .then(res => {
                    console.log(res);
                    this.downloading = false;
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', res.headers['content-disposition'].split('filename=')[1]);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch(e => {
                    console.log(e);
                    this.downloading = false;
                    this.$message.error(e.error);
                });
        },
        operation1() {
            this.$notify({
                title: '提示',
                message: this.selection
            });
        },
        operation2() {
            this.$message('操作2');
        },
        deleteRow(row) {
            this.$alert('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/collection/del/${row.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.getData();
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        this.$message.error(e.error);
                    }
                });
        }
    }
};
</script>
<style lang="less" scoped>
</style>
