<template>
    <div class="edit-view">
        <page-title>
            <el-button @click="$router.go(-1)" :disabled="saving">取消</el-button>
            <!-- <el-button @click="onDelete" :disabled="saving" type="danger" v-if="formData.id"> 删除 </el-button> -->
            <el-button @click="onSave" :loading="saving" type="primary">保存</el-button>
        </page-title>
        <div class="edit-view__content-wrapper">
            <div class="edit-view__content-section">
                <el-form
                    :model="formData"
                    :rules="rules"
                    ref="form"
                    label-width="140px"
                    label-position="right"
                    size="small"
                    style="max-width: 500px"
                >
                    <el-form-item label="项目" prop="projectId">
                        <project-select v-model="formData.projectId">
                        </project-select>
                    </el-form-item>
                    <el-form-item prop="name" label="名称">
                        <el-input v-model="formData.name" :disabled="!canEdit" :maxlength="16"></el-input>
                    </el-form-item>
                    <el-form-item prop="pic" label="图片">
                        <object-upload
                            v-model="formData.pic[0]"
                            :disabled="!canEdit"
                            compress
                            width="3000"
                            height="3000"
                        ></object-upload>
                    </el-form-item>
                    <el-form-item prop="minterId" label="创建者">
                        <minter-select
                            v-model="formData.minterId"
                            @detail="onMinterDetail"
                            :disabled="!canEdit"
                            :projectId="formData.projectId"
                        ></minter-select>
                    </el-form-item>
                    <el-form-item prop="category" label="分类">
                        <el-select v-model="formData.category" :disabled="!canEdit">
                            <el-option v-for="item in categories" :label="item" :value="item" :key="item"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="collectionCategoryId" label="筛选分类"  >
                        <el-select
                            v-model="formData.collectionCategoryId"
                            clearable=""
                        >
                            <el-option
                                v-for="item in collectionCategories"
                                :label="item.name"
                                :value="item.id"
                                :key="item.id"
                                @click.native="changeCategories(item)"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="collectionCategoryChildId" label="筛选子分类"  >
                        <el-select
                            v-model="formData.collectionCategoryChildId"
                            clearable=""
                            multiple
                        >
                            <el-option
                                v-for="item in collectionChildCategories"
                                :label="item.name"
                                :value="item.id"
                                :key="item.id"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="detail" label="详情" style="width: calc(100vw - 450px)">
                        <rich-text v-model="formData.detail"></rich-text>
                    </el-form-item>
                    <el-form-item prop="properties" label="特性" style="width: calc(100vw - 450px)" size="mini">
                        <el-table :data="formData.properties">
                            <el-table-column prop="name" label="名称">
                                <template v-slot="{ row }">
                                    <el-input v-model="row.name" placeholder="20字以内" maxlength="20"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column prop="value" label="内容">
                                <template v-slot="{ row }">
                                    <el-input v-model="row.value" placeholder="20字以内" maxlength="20"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column width="80" align="center">
                                <template v-slot="{ row, $index }">
                                    <el-button type="danger" plain size="mini" @click="delProperty($index)">
                                        删除
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form-item>
                    <el-form-item>
                        <el-button size="mini" @click="addProperty"> 添加特性 </el-button>
                    </el-form-item>
                    <!-- <el-form-item prop="type" label="类型">
                        <el-select v-model="formData.type" clearable filterable placeholder="请选择">
                            <el-option
                                v-for="item in typeOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="source" label="来源">
                        <el-select v-model="formData.source" clearable filterable placeholder="请选择">
                            <el-option
                                v-for="item in sourceOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item> -->
                    <el-form-item prop="price" label="价格">
                        <el-input-number type="number" v-model="formData.price" :disabled="!canEdit"></el-input-number>
                    </el-form-item>
                    <!-- <el-form-item
                        prop="originalPrice"
                        label="原价"
                    >
                        <el-input-number
                        type="number"
                        v-model="formData.originalPrice"
                        :disabled="!canEdit"
                        ></el-input-number>
                    </el-form-item> -->
                    <el-form-item
                        prop="serviceCharge"
                        label="手续费(%)"
                    >
                        <el-input-number
                        v-model="formData.serviceCharge"
                        :min="0"
                        :max="99"
                        :disabled="!canEdit"
                        >
                        </el-input-number>
                    </el-form-item>
                    <el-form-item prop="total" label="发行数量">
                        <el-input-number v-model="formData.total" disabled></el-input-number>
                    </el-form-item>
                    <el-form-item prop="limitBuyCount" label="限制购买数量">
                        <el-input-number v-model="formData.limitBuyCount"   :min="1"></el-input-number>
                    </el-form-item>
                    <el-form-item prop="items" label="包含作品" style="width: calc(100vw - 450px)">
                        <el-table :data="blindBoxItems">
                            <el-table-column
                                prop="collectionId"
                                label="名称"
                                :formatter="collectionNameFormatter"
                            ></el-table-column>
                            <el-table-column prop="total" label="数量" width="100"></el-table-column>
                            <el-table-column prop="stock" label="库存" width="100"></el-table-column>
                            <el-table-column prop="rare" label="稀有" width="100">
                                <template v-slot="{ row }">
                                    <el-tag :type="row.rare ? 'success' : 'info'">{{ row.rare ? '是' : '否' }}</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column width="100">
                                <template v-slot="{ row, $index }">
                                    <el-button
                                        @click="removeItem($index)"
                                        type="danger"
                                        plain
                                        size="mini"
                                        :disabled="!canEdit"
                                    >
                                        移除
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form-item>
                    <el-form-item>
                        <el-button size="mini" @click="addItem" :disabled="!canEdit">添加作品</el-button>
                    </el-form-item>
                    <!-- <el-form-item prop="likes" label="点赞">
                        <el-input-number v-model="formData.likes"></el-input-number>
                    </el-form-item> -->
                    <el-form-item
                        prop="variableType"
                        label="手动开启盲盒"
                    >
                        <el-switch
                        v-model="formData.variableType"
                        active-text="是"
                        inactive-text="否"
                        ></el-switch>
                    </el-form-item>
                    <el-form-item prop="startTime" label="定时发布">
                        <el-switch v-model="formData.scheduleSale" active-text="是" inactive-text="否"></el-switch>
                        <div style="margin-top: 10px" v-if="formData.scheduleSale">
                            <el-date-picker
                                v-model="formData.startTime"
                                type="datetime"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                placeholder="发布时间"
                            ></el-date-picker>
                        </div>
                    </el-form-item>
                    <el-form-item prop="onShelf" label="上架" v-if="!formData.scheduleSale">
                        <el-switch v-model="formData.onShelf" active-text="上架" inactive-text="下架"></el-switch>
                    </el-form-item>
                    <el-form-item prop="salable" label="可售">
                        <el-switch v-model="formData.salable" active-text="可销售" inactive-text="仅展示"></el-switch>
                    </el-form-item>
                    <el-form-item
                        prop="onAuction"
                        label="寄售开关"
                    >
                        <el-switch
                            v-model="formData.onAuction"
                            active-text="开启"
                            inactive-text="关闭"
                        ></el-switch>
                    </el-form-item>
                    <el-form-item class="form-submit">
                        <el-button @click="onSave" :loading="saving" type="primary" v-if="!formData.id">
                            保存
                        </el-button>
                        <!-- <el-button @click="onDelete" :disabled="saving" type="danger" v-if="formData.id">
                            删除
                        </el-button> -->
                        <el-button @click="$router.go(-1)" :disabled="saving">取消</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <el-dialog :visible.sync="showAddItemDialog" title="添加" width="600px">
            <el-form
                :model="addItemForm"
                label-position="right"
                label-width="80px"
                ref="addItemForm"
                :rules="addItemFormRules"
            >
                <el-form-item prop="collectionId" label="作品">
                    <el-select v-model="addItemForm.collectionId" :filterable="true">
                        <el-option v-for="item in collections" :label="item.name" :value="item.id" :key="item.id">
                            <span style="float: left">
                                {{ item.name }}<el-badge :value="item.stock"></el-badge>
                            </span>
                            <span style="float: right; color: #8492a6; font-size: 13px">
                                #{{ item.id }}
                                <el-tag >{{collectionTypeMap[item.type]}}</el-tag>
                            </span>
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="total" label="数量">
                    <el-input-number v-model="addItemForm.total" :min="1" :max="addItemForm.max || 0"></el-input-number>
                </el-form-item>
                <el-form-item prop="rare" label="稀有">
                    <el-switch v-model="addItemForm.rare" active-text="是" inactive-text="否"></el-switch>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button size="mini" @click="showAddItemDialog = false">取消</el-button>
                <el-button size="mini" @click="onAddItem" type="primary">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>

import {collectionTypeMap} from '@/utils/constant.js';

export default {
    name: 'BlindBoxEdit',
    created() {
        this.initCategoryList();
        if (this.$route.query.id) {
            this.$http
                .get('collection/get/' + this.$route.query.id)
                .then(res => {
                    res.properties = res.properties || [];
                    this.formData = res;
                })
                .catch(e => {
                    console.log(e);
                    this.$message.error(e.error);
                });
            this.$http
                .post(
                    '/blindBoxItem/all',
                    { query: { blindBoxId: this.$route.query.id }, size: 10000 },
                    { body: 'json' }
                )
                .then(res => {
                    this.blindBoxItems = res.content;
                });
        }
        this.$http
            .post(
                '/collection/all',
                {
                    query: { del: false,type:'DEFAULT', source: 'OFFICIAL', projectId: this.$store.state.projectId },
                    size: 10000,
                    sort: 'sort,desc;createdAt,desc'
                },
                { body: 'json' }
            )
            .then(res => {
                this.collections = res.content;
            });

        // this.formData = {
        //     name: 'OASISPUNK绿洲朋克',
        //     pic: ['https://awesomeadmin.oss-cn-hangzhou.aliyuncs.com/image/2021-10-21-16-44-52kZqxuwhH.gif'],
        //     minter: '管理员',
        //     minterId: 1,
        //     minterAvatar: 'https://awesomeadmin.oss-cn-hangzhou.aliyuncs.com/image/avatar_male.png',
        //     detail:
        //         '<div class="content-item" data-v-38285332="">\n<div data-v-38285332="">RAEX绿洲数字藏品中心首次携手星火爱心公益基金及火链Labs，联合发行公益型数字藏品: OASISPUNK绿洲朋克。OASISPUNK绿洲朋克是完全使用算法合成的加密人物头像，仅铸造发行3100枚，每一枚全都不同，更有稀缺度之分。绿洲朋克共分为3种类型：初代目（1500枚），次代目（1500枚），旗帜版（100枚）。每售出一枚绿洲朋克，收益所得将捐赠一定比例给&ldquo;星火爱心公益基金&rdquo;，用于扶贫助困类公益项目，同时买家将收到由星火爱心公益基金颁发的捐款证明，获得投身公益事业的荣誉感。欢迎你来到绿洲元宇宙，共建绿洲生态，共享绿洲文明荣耀。</div>\n</div>',
        //     type: 'BLIND_BOX',
        //     source: 'OFFICIAL',
        //     sale: 0,
        //     stock: 0,
        //     total: 23,
        //     likes: 0,
        //     onShelf: true,
        //     salable: true,
        //     price: 0.01,
        //     properties: [],
        //     canResale: false,
        //     royalties: 0,
        //     serviceCharge: 0,
        // };
        // this.blindBoxItems = [
        //     {
        //         collectionId: 212,
        //         total: 3,
        //         rare: true
        //     },
        //     {
        //         collectionId: 207,
        //         total: 6
        //     },
        //     {
        //         collectionId: 201,
        //         total: 14
        //     }
        // ];
    },
    computed: {
        canEdit() {
            return !!!this.$route.query.id;
        }
    },
    data() {
        return {
            saving: false,
            collectionCategories: [],
            formData: {
                projectId: this.$store.state.projectId,
                onShelf: true,
                salable: true,
                onAuction: false,
                variableType: false,
                properties: [],
                type: 'BLIND_BOX',
                source: 'OFFICIAL',
                pic: [{}],
                scheduleSale: true,
                privileges: [],
                collectionCategory:{
                    id: ''
                },
                collectionCategoryId:null,
                limitBuyCount: 10
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: '请输入名称',
                        trigger: 'blur'
                    }
                ],
                pic: [
                    {
                        validator: (rule, value, callback) => {
                            if (value) {
                                if (!(value instanceof Array)) {
                                    callback(new Error('请上传内容'));
                                    return;
                                } else {
                                    for (let f of value) {
                                        if (!f.url) {
                                            callback(new Error('请上传内容'));
                                            return;
                                        }
                                    }
                                }
                                callback();
                            } else {
                                callback(new Error('请上传内容'));
                            }
                        },
                        trigger: 'blur'
                    }
                ],
                minter: [
                    {
                        required: true,
                        message: '请输入创建者',
                        trigger: 'blur'
                    }
                ],
                minterId: [
                    {
                        required: true,
                        message: '请输入铸造者ID',
                        trigger: 'blur'
                    }
                ],
                minterAvatar: [
                    {
                        required: true,
                        message: '请输入铸造者头像',
                        trigger: 'blur'
                    }
                ],
                detail: [
                    {
                        required: true,
                        message: '请输入详情',
                        trigger: 'blur'
                    }
                ],
                type: [
                    {
                        required: true,
                        message: '请输入类型',
                        trigger: 'blur'
                    }
                ],
                source: [
                    {
                        required: true,
                        message: '请输入来源',
                        trigger: 'blur'
                    }
                ],
                total: [
                    {
                        required: true,
                        message: '请输入发行数量',
                        trigger: 'blur'
                    }
                ],
                limitBuyCount: [
                    {
                        required: true,
                        message: '请输入限制购买数量',
                        trigger: 'blur'
                    }
                ],
                onShelf: [
                    {
                        required: true,
                        message: '请输入上架',
                        trigger: 'blur'
                    }
                ],
                price: [
                    {
                        required: true,
                        message: '请输入价格',
                        trigger: 'blur'
                    }
                ],
                originalPrice: [
                    {
                        required: true,
                        message: '请输入原价',
                        trigger: 'blur'
                    }
                ],
                properties: [
                    {
                        validator: (rule, value, callback) => {
                            if (value) {
                                if (!(value instanceof Array)) {
                                    callback(new Error('properties must be array!'));
                                    return;
                                } else {
                                    for (let i = 0; i < value.length; i++) {
                                        if (value[i].name === '' || value[i].name === undefined) {
                                            callback(new Error('请填写名称'));
                                            return;
                                        }
                                        if (value[i].value === '' || value[i].value === undefined) {
                                            callback(new Error('请添加作品'));
                                            return;
                                        }
                                    }
                                }
                            }
                            callback();
                        },
                        trigger: 'blur'
                    }
                ],
                startTime: [
                    {
                        validator: (rule, value, callback) => {
                            if (this.formData.scheduleSale === true && !value) {
                                callback(new Error('请填写开售时间'));
                                return;
                            }
                            callback();
                        }
                    }
                ],
                items: [
                    {
                        validator: (rule, value, callback) => {
                            if (this.blindBoxItems.length === 0) {
                                callback(new Error('请添加盲盒内容'));
                                return;
                            }
                            callback();
                        }
                    }
                ],
                serviceCharge: [{ required: true, message: '请填手续费' }],
                category: [{ required: true, message: '请填写分类' }],
                projectId: [{ required: true, message: '请选择项目' }]
            },
            typeOptions: [
                { label: '默认', value: 'DEFAULT' },
                { label: '盲盒', value: 'BLIND_BOX' },
                { label: '拍卖', value: 'AUCTION' }
            ],
            sourceOptions: [
                { label: '官方', value: 'OFFICIAL' },
                { label: '用户铸造', value: 'USER' },
                { label: '转让', value: 'TRANSFER' }
            ],
            collections: [],
            checkCategories: {},
            collectionChildCategories: {},
            showAddItemDialog: false,
            blindBoxItems: [],
            addItemForm: {},
            addItemFormRules: {
                id: [{ required: true, message: '请选择作品' }],
                total: [{ required: true, message: '请输入数量' }]
            },
            categories: ['勋章', '数字资产', '数字艺术', '门票', '游戏', '音乐', '使用', '其他'],
            collectionTypeMap
        };
    },
    methods: {
        onSave() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.formData.collectionCategoryId = this.formData.collectionCategoryId && this.formData.collectionCategoryChildId ? this.formData.collectionCategoryChildId.join(',') : this.formData.collectionCategoryId;
                    this.submit();
                } else {
                    return false;
                }
            });
        },
        submit() {
            let addData = {
                ...this.formData,
                collectionCategory: null
            };

            if(this.formData.collectionCategoryId){
                addData.collectionCategory = {
                    id: this.formData.collectionCategoryId
                };
            }
            if (this.formData.id) {

                this.saving = true;
                this.$http
                    .post('/collection/save', addData, { body: 'json' })
                    .then(res => {
                        this.saving = false;
                        this.$message.success('成功');
                        this.$router.go(-1);
                    })
                    .catch(e => {
                        console.log(e);
                        this.saving = false;
                        this.$message.error(e.error);
                    });
            } else {
                let updateData = { blindBox: addData , items: this.blindBoxItems };

                this.saving = true;
                this.$http
                    .post('/collection/createBlindBox', updateData, { body: 'json' })
                    .then(res => {
                        this.saving = false;
                        this.$message.success('成功');
                        this.$router.go(-1);
                    })
                    .catch(e => {
                        console.log(e);
                        this.saving = false;
                        this.$message.error(e.error);
                    });
            }
        },
        onDelete() {
            this.$confirm('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/collection/del/${this.formData.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        console.log(e);
                        this.$message.error((e || {}).error || '删除失败');
                    }
                });
        },
        onMinterDetail(e) {
            console.log(e);
            this.$set(this.formData, 'minter', e.nickname);
            this.$set(this.formData, 'minterAvatar', e.avatar);
        },
        addProperty() {
            this.formData.properties.push({
                name: '',
                value: ''
            });
        },
        delProperty(i) {
            this.formData.properties.splice(i, 1);
        },
        addItem() {
            this.addItemForm = { rare: false };
            this.showAddItemDialog = true;
        },
        onAddItem() {
            this.$refs.addItemForm
                .validate()
                .then(_ => {
                    this.addItemForm.stock = this.addItemForm.total;
                    this.blindBoxItems.push({ ...this.addItemForm });
                    this.showAddItemDialog = false;
                })
                .catch(_ => {});
        },
        collectionNameFormatter(row, column, value, index) {
            return (this.collections.find(i => i.id === value) || {}).name || '';
        },
        removeItem(i) {
            this.blindBoxItems.splice(i, 1);
        },
         initCategoryList() {
            const params = {
                page: 0,
                size: 10000,
                sort: 'createdAt,desc',
                search: '',
                query: {
                    del: false,
                    source: 'OFFICIAL',
                    createdAt: '',
                    minterId: '',
                    projectId: this.$store.state.projectId
                }
            };
            this.$http
                .post('collectionCategory/all', params, { body: 'json' })
                .then(res => {
                    this.collectionCategories = res || [];
                })
                .catch(e => {
                    console.log(e);
                    this.$message.error(e.error);
                });
        },
        changeCategories(item) {
            this.checkCategories = item;
            this.collectionChildCategories = item.children;
        }
    },
    watch: {
        'addItemForm.collectionId'(val) {
            if (val) {
                this.$set(this.addItemForm, 'max', (this.collections.find(i => i.id === val) || {}).stock || 0);
            }
        },
        blindBoxItems() {
            this.$set(
                this.formData,
                'total',
                this.blindBoxItems.map(i => i.total).reduce((a, b) => a + b, 0)
            );
        },
        'formData.serviceCharge'(newVal, oldVal) {
            let seat = String(newVal).indexOf('.') + 1
            let count = String(newVal).length - seat
            if(count != 1) {
                this.formData.serviceCharge = newVal.toFixed(1)
            }

        }
    }
};
</script>
<style lang="less" scoped>
    .tips{
        color:#666;
        font-size:12px;
        float:right;
    }
</style>
