var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list-view"},[_c('page-title',[_c('el-button',{staticClass:"filter-item",attrs:{"type":"primary","icon":"el-icon-plus","disabled":_vm.fetchingData || _vm.downloading},on:{"click":_vm.addRow}},[_vm._v(" 新增 ")])],1),_c('div',{staticClass:"filters-container"},[_c('minter-filter',{attrs:{"projectId":_vm.$store.state.projectId},on:{"input":_vm.initData},model:{value:(_vm.minterId),callback:function ($$v) {_vm.minterId=$$v},expression:"minterId"}}),_c('el-input',{staticClass:"filter-item search",attrs:{"placeholder":"搜索...","clearable":""},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.initData($event)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_c('el-button',{attrs:{"slot":"append","icon":"el-icon-search"},on:{"click":_vm.initData},slot:"append"})],1)],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.fetchingData),expression:"fetchingData"}],ref:"table",attrs:{"data":_vm.tableData,"row-key":"id","header-row-class-name":"table-header-row","header-cell-class-name":"table-header-cell","row-class-name":"table-row","cell-class-name":"table-cell","height":_vm.tableHeight}},[(_vm.multipleMode)?_c('el-table-column',{attrs:{"align":"center","type":"selection","width":"50"}}):_vm._e(),_c('el-table-column',{attrs:{"prop":"id","label":"ID","width":"100"}}),_c('el-table-column',{attrs:{"prop":"name","label":"名称","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"createdAt","label":"创建时间","width":"150"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('sortable-header',{attrs:{"column":column,"current-sort":_vm.sort},on:{"changeSort":_vm.changeSort}})]}}])}),_c('el-table-column',{attrs:{"prop":"pic","label":"作品内容"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-image',{staticStyle:{"width":"30px","height":"30px"},attrs:{"src":row.pic[0].thumb || row.pic[0].url,"fit":"cover","preview-src-list":row.pic.map(function (i) { return i.thumb || i.url; })}})]}}])}),_c('el-table-column',{attrs:{"prop":"minter","label":"铸造者"}}),_c('el-table-column',{attrs:{"prop":"minterId","label":"铸造者ID"}}),_c('el-table-column',{attrs:{"prop":"type","label":"类型","formatter":_vm.typeFormatter}}),_c('el-table-column',{attrs:{"prop":"source","label":"来源","formatter":_vm.sourceFormatter}}),_c('el-table-column',{attrs:{"prop":"total","label":"发行数量"}}),_c('el-table-column',{attrs:{"prop":"stock","label":"剩余库存"}}),_c('el-table-column',{attrs:{"prop":"onShelf","label":"上架"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.onShelf)?_c('el-tag',{attrs:{"type":"success"}},[_vm._v("是")]):_c('el-tag',{attrs:{"type":"info"}},[_vm._v("否")])]}}])}),_c('el-table-column',{attrs:{"prop":"salable","label":"仅展示","width":"90","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(!row.salable)?_c('el-tag',{attrs:{"type":"success"}},[_vm._v("是")]):_c('el-tag',{attrs:{"type":"info"}},[_vm._v("否")])]}}])}),_c('el-table-column',{attrs:{"prop":"price","label":"价格"}}),_c('el-table-column',{attrs:{"prop":"serviceCharge","label":"手续费(%)","width":"90"}}),_c('el-table-column',{attrs:{"prop":"sort","label":"排序","width":"90","align":"center"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('sortable-header',{attrs:{"column":column,"current-sort":_vm.sort},on:{"changeSort":_vm.changeSort}})]}}])}),_c('el-table-column',{attrs:{"label":"操作","align":"center","fixed":"right","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"primary","size":"mini","plain":""},on:{"click":function($event){return _vm.editRow(row)}}},[_vm._v("查看")]),_c('el-button',{attrs:{"type":"danger","size":"mini","plain":""},on:{"click":function($event){return _vm.deleteRow(row)}}},[_vm._v("删除")])]}}])})],1),_c('div',{staticClass:"pagination-wrapper"},[_c('el-pagination',{attrs:{"background":"","current-page":_vm.page,"page-sizes":[10, 20, 30, 40, 50],"page-size":_vm.pageSize,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.totalElements},on:{"size-change":_vm.onSizeChange,"current-change":_vm.onCurrentChange}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }